var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "v-card--plan mx-auto pt-3 pb-4 px-2 text-center",
      attrs: {
        color: !_vm.plan.best ? "transparent elevation-0" : undefined,
        light: _vm.plan.best,
        "max-width": "100%"
      }
    },
    [
      _c("div", {
        staticClass: "body-2 text-uppercase grey--text",
        domProps: { textContent: _vm._s(_vm.plan.heading) }
      }),
      _c(
        "v-avatar",
        { attrs: { size: "130" } },
        [
          _c("v-icon", {
            attrs: {
              color: !_vm.plan.best ? undefined : "success",
              size: "64"
            },
            domProps: { textContent: _vm._s(_vm.plan.icon) }
          })
        ],
        1
      ),
      _c("h2", {
        staticClass: "display-2 font-weight-light",
        domProps: { textContent: _vm._s(_vm.plan.title) }
      }),
      _c("v-card-text", {
        staticClass: "body-1 font-weight-light pa-1",
        domProps: { textContent: _vm._s(_vm.plan.text) }
      }),
      _c(
        "pages-btn",
        { attrs: { color: !_vm.plan.best ? "white" : "success" } },
        [_vm._v("\n    " + _vm._s(_vm.$t("plan")) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }